import { render, staticRenderFns } from "./Recommend.vue?vue&type=template&id=6617d12d&scoped=true&"
import script from "./Recommend.vue?vue&type=script&lang=js&"
export * from "./Recommend.vue?vue&type=script&lang=js&"
import style0 from "./Recommend.vue?vue&type=style&index=0&id=6617d12d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6617d12d",
  null
  
)

export default component.exports
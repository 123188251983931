<template>
	<div>
		<div class="recommend_wrap" v-if="recommend_list.length>0">
			<div class="recommend_title" :style="{
				backgroundImage: 'url('+ require('@/assets/home/title_bg.png') +')',
				backgroundSize: '100px 10px',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center'
			}">{{title}}</div>
			<div class="recommend_content">
				<div class="recommend_item" v-for="i in recommend_list" @click="$router.push({path:'/GoodsItem', query: {id: i.goods_id}})">
					<div class="recommend_image">
						<img v-lazy="i.pict_url" >
					</div>
					<div class="goods_desc">
						<div>
							<div class="recommend_goods_title">
								{{i.goods_title}}
							</div>
							<div class="goods_ext1">
								<div class="goods_price">¥{{ i.price }}</div>
								<div class="goods_volume">月销{{i.volume}}件</div>
							</div>
						</div>
						<div class="goods_ext2">
							<div class="coupon_amount">{{parseFloat(i.coupon_amount)}}元券</div>
							<div class="share_amount">预估收益¥{{i.commission}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue"
import ls from "@/libs/localstore"
import { getRecommendProducts } from '@/api/product'
import {Lazyload} from "vant"

Vue.use(Lazyload)

export default {
	components:{},
	props: {
		product_id: '',
		title: {
			type: String,
			default: '猜你喜欢'
		},
	},
	data(){
		return {
			recommend_list: [],
		}
	},
	watch: {
		product_id: function(newVal, oldVal) {
			this._loadRecommendGoods()
		}
	},
	computed:{},
	created(){},
	mounted(){
		this._loadRecommendGoods()
	},
	methods:{
		_loadRecommendGoods() {
			let params = {
				 
				product_id: this.product_id,
				rows: 6
      }
      getRecommendProducts(params).then((res) => {
        if (res.errCode == 0) {
          if (res.data.length > 0) {
            this.recommend_list = res.data
          }
        }
      }).catch((err) => {
        console.log(err.errMsg)
      })
		},
	}
}
</script>

<style scoped>
.recommend_wrap {
	position: relative;
	background: #fff;
	padding: 10px 0;
}

.recommend_title {
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 45px;
}

.recommend_content {
	display: flex;
	flex-wrap: wrap;
	justify-content:space-around;
}

.recommend_item {
	width: 100%;
	margin: 0 10px;
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
	background: #FFFFFF;
	margin-bottom: 10px;
}

.recommend_image {
	flex: none;
	width: 80px;
	height: 80px;
	background: #fff;
	font-size: 0;
	border-radius: 6px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.recommend_image img {
	width: 100%;
}

.goods_desc {
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.recommend_price {
	padding: 0 5px;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
}

.recommend_goods_title {
	font-size: 13px;
	line-height: 20px;
	color: #4a4a4a;
	letter-spacing: 0.67px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.recommend_bottom {
	height: 8px;
	background: #fff;
	overflow: hidden;
}
.goods_ext1, .goods_ext2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.goods_price {
	color: #F23182;
	font-size: 16px;
}
.goods_volume {
	font-size: 12px;
	color: #9B9B9B;
	letter-spacing: 2rpx;
}
.coupon_amount {
	line-height: 17px;
	padding: 0 5px;
	background: #99B0FF;
	background-size: 100% 100%;
	border-radius: 4px;
	text-align: center;
	color: #fff;
}
.share_amount {
	padding: 0 5px;
	line-height: 17px;
	background-image: linear-gradient(90deg, #FF6284 9%, #FFA554 100%);
	border-radius: 4px;
	color: #fff;
	text-align: center;
}
</style>
<template>
  <div class="page" v-if="_haveData_">
    <!-- 商品视频图片 -->
    <div class="goods-swipe" ref="GoodsSwipe" width="100%">
      <template v-if="item_data.goods_images.length>0">
        <van-swipe :autoplay="5000" ref="Banner" indicator-color="#fff">
          <van-swipe-item
            v-for="(item, index) in item_data.goods_images"
            :key="index"
            @click="imgPreview(index);"
          >
            <img :style="{width: '100%',height:'375px'}" v-lazy="item" />
          </van-swipe-item>
        </van-swipe>
      </template>
      <template v-else>
        <div class="image_blank"></div>
      </template>
    </div>

    <!-- 商品基本信息 -->
    <div class="info_zone">
      <div class="goodsInfo">
        <!-- 商品标题 -->
        <div class="title">
          <span class="platform_cn" v-if="!!item_data.platform_cn">{{item_data.platform_cn}}</span>
          <span class>{{item_data.goods_title}}</span>
        </div>
        <div class="show_text_1">
          <div class="goods_price">
            <div class="show_price_active">
              <span class="sym">¥</span>
              <span class="price">{{item_data.price}}</span>
            </div>
            <div>
              <span class="original_price">¥{{item_data.reserve_price}}</span>
            </div>
          </div>
          <div class="coupon">
            <div class="coupon_amount" :style="padding_bg">
              <span v-if="!!item_data.coupon_amount">
                <span class>{{item_data.coupon_amount}}</span> 元券
              </span>
            </div>
            <div class="sales">
              <span class="f12">月销量</span>
              <span class="f12">{{item_data.volume}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-button
      v-if="show_link==false"
      class="award"
      @click="getLink"
    >购买返¥{{item_data.user_commission}}</van-button>
    <div class="taobao_link" v-if="show_link">
      <p>预估收益¥{{item_data.user_commission}}</p>
      <van-button v-clipboard:copy="promotionData.tao_token" @click="_copy">一键复制口令</van-button>
      <div class="command">{{promotionData.tao_token}}</div>
      <div class="copy" v-if="!!promotionData.tao_token">复制口令，打开【手机淘宝】即可领券购买</div>
    </div>
    <!-- 商品详情 -->
    <div class="mb20">
      <goods-content :content="goods_content" :detail_images="detail_images"></goods-content>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  ImagePreview,
  Row,
  Col,
  Sku,
  Popup,
  Button,
  Lazyload,
  Swipe,
  SwipeItem,
  Loading,
} from "vant";
import StatusBar from "@/components/StatusBar";
import GoodsContent from "./components/GoodsContent";
import ls from "@/libs/localstore";
import {
  getProductDetail as getProduct,
  getDetailContent,
  getGoodsShareLink,
  getPromotionData,
} from "@/api/product";
import VueClipboards from "vue-clipboards";
import ShowCustomerQrcode from "@/components/customer/Show";
import ReviewBar from "./components/ReviewBar";
import Recommend from "./components/Recommend";
import { isMicroMessenger, isIOS } from "@/utils/user-agent";
import QRCode from "qrcode";
import html2canvas from "html2canvas";

import "@/utils/ap";

Vue.use(ImagePreview)
  .use(Row)
  .use(Col)
  .use(Sku)
  .use(Popup)
  .use(VueClipboards)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(Loading)
  .use(Lazyload);

export default {
  name: "GoodsItem",
  components: {
    StatusBar,
    GoodsContent,
    ShowCustomerQrcode,
    ReviewBar,
    Recommend,
  },
  data() {
    return {
      hide_btn: false,
      is_generate_done: false,
      qrcode: null,
      is_shot: false,
      is_getPromotion: false,
      showShare: false,
      is_copy: false,
      dialogshow: false,
      product_id: "",
      goods_content: "",
      detail_images: [],
      item_data: {},
      show_link: false,
      promotionData: {},
    };
  },
  created() {},
  computed: {
    padding_bg(){
      if(!!this.item_data.coupon_amount){
        return " background: rgba(255, 246, 238, 1);"
      }else {
        return " background: transparent"

      }
    },
    _haveData_() {
      if (Object.keys(this.item_data).length > 0) {
        return true;
      }
      return false;
    },
    _getCartCount() {
      if (this.cartCount == 0) {
        return "";
      }
      return this.cartCount;
    },
    _getTaoToken() {
      return this.item_data.tao_token;
    },
    _userAvatar_() {
      return ls.getAvatar();
    },
    _userName_() {
      return ls.getNickName();
    },
  },

  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.product_id = to.query.id;
        this.item_data = {};
        this.is_getPromotion = false;
        let that = this;
        setTimeout(function () {
          that._loadData();
        }, 200);
      }
    },
  },
  mounted() {
    let that = this;
    setTimeout(function () {
      that._loadData();
    }, 200);
  },
  methods: {
    //粘贴
    _copy() {
      this.$toast.success("复制成功");
    },
    //获取淘口令链接
    getLink() {
      this.$toast.loading({
        duration: 0,
        loadingType: "spinner",
        message: "加载中...",
        mask: false,
        forbidClick: true,
      });
      let params = {
         
        goods_id: this.$route.query.id,
      };
      getPromotionData(params).then((res) => {
        if (res.errCode == 0) {
          this.$toast.clear();
          if (
            this.item_data.platform_cn == "淘宝" ||
            this.item_data.platform_cn == "天猫"
          ) {
						this.promotionData = res.data;
            this.show_link = true;
          } else {
            window.location.href = res.data.coupon_click_url;
          }
        }
      });
    },
    //加载数据
    _loadData() {
      this.$toast.loading({
        duration: 0,
        loadingType: "spinner",
        message: "加载中...",
        mask: false,
        forbidClick: true,
      });
      let goods_id = this.$route.query.id;
      this.product_id = goods_id;
      let params = {
        goods_id: this.$route.query.id,
      };
      getProduct(params)
        .then((res) => {
          if (res.errCode == 0) {
            this.item_data = res.data;

            this._initShare();
          }
          this.$toast.clear();
          this._getDetailContent();
        })
        .catch((err) => {
          this.$toast.clear();
        });
    },
    _postClick() {},
    _closeMask() {
      this.showShare = false;
    },
    _initShare() {
      this._shareTimeline();
      this._shareAppMessage();
      this._updateTimelineShareData();
      this._updateAppMessageShareData();
    },
    _shot() {
      this.hide_btn = true;
      let dom = this.$refs.post;

      let options = {
        scale: window.devicePixelRatio * 2,
        useCORS: true,
        allowTaint: true,
      };

      let that = this;
      setTimeout(function () {
        html2canvas(dom, options).then(function (canvas) {
          that.is_generate_done = true;
          that.hide_btn = true;
          let url = canvas.toDataURL("image/png", 1.0);
          that.shotPic = url;
          that.is_shot = true;
        });
      }, 1000);
    },
    _getDetailContent() {
      let id = this.$route.query.id;
      let params = {
         
        goods_id: id,
      };
      getDetailContent(params).then((res) => {
        if (res.errCode === 0) {
          if (res.data.type == "images") {
            this.detail_images = res.data.data;
          } else {
            this.goods_content = res.data.data;
          }
        }
      });
    },
    generate_qrcode(subject) {
      let options = {
        type: "png",
        width: "100px",
        margin: "0",
      };
      QRCode.toDataURL(subject, options)
        .then((url) => {
          this.qrcode = url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 右上角菜单分享朋友圈
    _shareTimeline() {
      let that = this;
      this.$wechat.onMenuShareTimeline({
        title: that.item_data.goods_title, // 分享标题
        link:
          "http://tjf.quanminhuyu.com/goto.html?goto=goodsItem&params=" +
          encodeURIComponent("id=" + that.$route.query.id),
        imgUrl: that.item_data.pict_url, // 分享图标
        success: function () {},
      });
    },
    // 右上角菜单分享给朋友
    _shareAppMessage() {
      let that = this;
      this.$wechat.onMenuShareAppMessage({
        title: that.item_data.goods_title, // 分享标题
        desc: "通过淘街坊购买不仅可以领取大额优惠券还能获得返利哦", // 分享描述
        link:
          "http://tjf.quanminhuyu.com/goto.html?goto=goodsItem&params=" +
          encodeURIComponent("id=" + that.$route.query.id),
        imgUrl: that.item_data.pict_url, // 分享图标
        success: function () {},
      });
    },
    // 分享朋友圈
    _updateTimelineShareData() {
      let that = this;
      this.$wechat.ready(function () {
        that.$wechat.updateTimelineShareData({
          title: that.item_data.goods_title, // 分享标题
          link:
            "http://tjf.quanminhuyu.com/goto.html?goto=goodsItem&params=" +
            encodeURIComponent("id=" + that.$route.query.id),
          imgUrl: that.item_data.pict_url, // 分享图标
          success: function () {},
        });
      });
    },
    // 分享朋友
    _updateAppMessageShareData() {
      let that = this;
      this.$wechat.ready(function () {
        that.$wechat.updateAppMessageShareData({
          title: that.item_data.goods_title, // 分享标题
          desc: "通过淘街坊购买不仅可以领取大额优惠券还能获得返利哦", // 分享描述
          link:
            "http://tjf.quanminhuyu.com/goto.html?goto=goodsItem&params=" +
            encodeURIComponent("id=" + that.$route.query.id),
          imgUrl: that.item_data.pict_url, // 分享图标
          success: function () {},
        });
      });
    },
    onClickCustomer(mes) {
      this.$refs.ShowQrcode.show();
    },
    _cantBuy() {
      this.$toast(this.reason);
    },
    _copyTaoTokenSuccess() {
      this.is_copy = true;
    },
    // 获取购物车商品列表
    _showCartList() {
      this.$router.push({ path: "/cart" });
      return false;
    },
    imgPreview(index) {
      this.isShowBackBtn = false;
      let that = this;
      ImagePreview({
        images: this.goods_images,
        startPosition: index,
        closeOnPopstate: true,
        onClose() {
          // do something
          that.isShowBackBtn = true;
        },
      });
    },
    // 分享按钮点击
    onShareClick() {
      if (!this.is_getPromotion) {
        let params = {
           
          goods_id: this.$route.query.id,
        };
        getGoodsShareLink(params)
          .then((res) => {
            if (res.errCode === 0) {
              this.generate_qrcode(res.data.share_link);
              this.item_data.tao_token = res.data.tao_token;
              this.item_data.coupon_click_url = res.data.coupon_click_url;
              this.item_data.schema_url = res.data.schema_url;
              this.item_data.share_link = res.data.share_link;
              this.is_getPromotion = true;
              setTimeout(() => {
                this._showPost();
              }, 1000);
            }
          })
          .catch((err) => {
            if (err.errCode === "ERR_07000") {
              this._needTbAuth();
            }
          });
      } else {
        this._showPost();
      }
    },
    // 分享海报
    _showPost() {
      this.showShare = true;
      this.hide_btn = true;
      let that = this;
      window.scrollTo({ top: 0 });
      setTimeout(function () {
        that.hide_btn = false;
      }, 1000);
    },
    // 商品购买、优惠券按钮点击
    onBuyClick() {
      // 链接
      if (!this.is_getPromotion) {
        let params = {
           
          goods_id: this.$route.query.id,
        };
        getGoodsShareLink(params)
          .then((res) => {
            if (res.errCode === 0) {
              this.generate_qrcode(res.data.share_link);
              this.item_data.tao_token = res.data.tao_token;
              this.item_data.coupon_click_url = res.data.coupon_click_url;
              this.item_data.schema_url = res.data.schema_url;
              this.item_data.share_link = res.data.share_link;
              this.is_getPromotion = true;
              this._gotoBuy();
            }
          })
          .catch((err) => {
            if (err.errCode === "ERR_07000") {
              this._needTbAuth();
            }
          });
      } else {
        this._gotoBuy();
      }
    },
    // 跳转第三方商品购买页面
    _gotoBuy() {
      console.log(12345);
      let url = this.item_data.coupon_click_url || this.item_data.click_url;

      if (
        (this.item_data.platform == 1 || this.item_data.platform == 2) &&
        isMicroMessenger
      ) {
        if (this.item_data.tao_token) {
          this.dialogshow = true;
          this.is_copy = false;
          return true;
        }
      }

      window.location.href = url;
    },
    // 需要淘宝授权
    _needTbAuth() {
      this.$dialog
        .confirm({
          title: "提示",
          showCancelButton: true,
          confirmButtonText: "下载",
          cancelButtonText: "再看看",
          message: "需要下载淘街坊app然后进行淘宝授权，即可享受超值优惠",
        })
        .then(() => {
          if (isIOS) {
            window.location.href =
              "https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745";
          } else {
            window.location.href =
              "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  padding-bottom: 70px;
  text-align: left;
  position: relative;
  background: #ffffff;
}
#back_btn {
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 99999;
}
.image_blank {
  height: 300px;
  background: #f0f0f0;
}
.van-swipe-item {
  font-size: 0px;
}
.goods-swipe {
  position: relative;
  width: 100%;
  z-index: 0;
  height: 375px;
}
.goods-tips {
  width: 100%;
  height: 40px;
  font-family: "PingFangSC-Medium";
  font-size: 10px;
  color: #ffffff;
  letter-spacing: 1px;
  background: linear-gradient(90deg, #d6adff 0%, #ff81a7 100%);
  text-align: center;
  line-height: 40px;
}
.title {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  text-align: left;
  line-height: 24px;
  margin-bottom: 10px !important;
}
.title .platform_cn {
  display: inline-block;
  padding: 0 3px;
  margin-right: 2px;
  border: 1px solid #fe5251;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  color: #fe5251;
}
.show_text_1 {
  display: flex;
  align-items: center;
  .goods_price {
    display: flex;
    align-items: baseline;
    .show_price_active {
      color: #f40337;
      margin-right: 5px;

      .price {
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: 500;
      }
      .sym {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .original_price {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-left: 12px;
      text-decoration: line-through;
      color: rgba(153, 153, 153, 1);
    }
  }

  .coupon {
    flex: 1;
    height: 22.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;

    .coupon_amount {
      padding: 0 10px;
      height: 22.5px;
      line-height: 22.5px;
      text-align: center;
      background: rgba(255, 246, 238, 1);
      border-radius: 3px;
      font-size: 14px;
      color: #ff8400;
    }
    .sales {
      line-height: 13px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
    }
  }
}

.goodsInfo {
  width: 337px;
  margin: 18.5px auto 0;
}
.info_zone {
  position: relative;
  background: #fff;
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  #back_btn {
    top: 42px;
  }
}

.page /deep/ .van-sku-actions .van-button--danger {
  border-color: #ff7c8a;
  background: #ff7c8a;
}

.page /deep/ .van-sku-actions .van-button--warning {
  border-color: #ffcd7c;
  background: #ffcd7c;
}

.goods_title {
  font-size: 12px;
  color: #030303;
  letter-spacing: 0;
  text-align: left;
  line-height: 18px;
}
.award {
  margin: 45px auto 20px;
  width: 337px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(
    270deg,
    rgba(255, 85, 149, 1),
    rgba(255, 108, 115, 1)
  );
  border-radius: 4px;
  font-size: 17px;
  color: #ffffff;
}
.taobao_link {
  background: url("../../assets/common/tjf_gzh_gwfl_copy_bg_img.png") no-repeat;
  background-size: 100% 100%;
  width: 337px;
  height: 174.5px;
  margin: 29px auto 20px;
  padding: 18.5px 0 20px;
  box-sizing: border-box;
  text-align: center;
  p {
    font-size: 16px;
    line-height: 16px;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(255, 80, 80, 1);
  }
  .van-button--normal {
    width: 200px;
    height: 37px;
    background: linear-gradient(
      270deg,
      rgba(255, 108, 115, 1),
      rgba(255, 85, 149, 1)
    );
    border-radius: 4px;
    font-size: 15px;
    color: #ffffff;
    margin: 20px 0 19px;
  }
  .command {
    font-size: 17px;
    line-height: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(68, 68, 68, 1);
  }
  .copy {
    font-size: 13px;
    line-height: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(68, 68, 68, 1);
    margin-top: 15px;
  }
}
</style>
